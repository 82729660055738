import { toHTML } from "../helper/functions";

class MakerList extends HTMLElement {
    constructor() {
      super();
    }
  
    connectedCallback() {
        this.fetchBlogsByLetter("a");
        this.bindListeners();
    }

    bindListeners() {
      const letters = this.querySelectorAll('.filter-letter');
      letters.forEach((letter) => {
        letter.addEventListener('change', (event) => {
          console.log(event.target.value)
          this.fetchBlogsByLetter(`${event.target.value.toLowerCase()}`)
        })
      })
    }

    fetchBlogsByLetter(letter) {
    	// Replace 'your-store.myshopify.com' with your actual Shopify store domain
    	const apiVersion = '2023-04';
    	const shopifyDomain = 'eatingtools.myshopify.com';
    	//This token is okay to expose as it only has access to blog information.
    	const storefrontAccessToken = '14ae49747c7156ac6c58749c8cec9980';

    	// Fetch blogs
    	fetch('https://' + shopifyDomain + '/api/' + apiVersion + '/graphql.json', {
    			method: 'POST',
    			headers: {
    				'Content-Type': 'application/json',
    				'X-Shopify-Storefront-Access-Token': storefrontAccessToken
    			},
    			body: JSON.stringify({

    				query: `
        {
          blogs(first: 100, query:"(title:${letter}*) AND (-title:'Blog')") {
            edges {
              node {
                featured_image: metafield(namespace: "maker", key: "featured_image") {
                  type
                  value
                  reference {
                    __typename
                    ... on MediaImage {
                      id
                      image {
                        url
                      }
                    }
                  }
                }
                description: metafield(namespace: "custom", key: "maker_description") {
                  type
                  value
                }
                location: metafield(namespace: "maker", key: "location") {
                  type
                  value
                }
                id
                title
                handle
              }
            }
          }
        }
        `
    			})
    		})
    		.then(response => response.json())
    		.then(data => {
    			console.log(data)
    			var blogs = data.data.blogs.edges.map(edge => edge.node);
    			// need to make sure to remove any results that don't match what we are actually trying to do.
    			// which is return blogs where the title starts with the selected letter.
    			// to make this more robust we can have a 1 word identifier as a metafield and this filter will work on the fetch results level.
    			const filteredBlogs = blogs.filter(blog =>
    				blog.title.toLowerCase().startsWith(`${letter}`)
    			);

    			console.log(filteredBlogs)

    			// Display blogs
    			var blogList = this.querySelector('#blog-list');
    			//clear old list of makers
    			blogList.innerHTML = ""

          if(filteredBlogs.length >= 1) {
            filteredBlogs.forEach(function(blog) {
              var li = document.createElement('li');
  
              li.classList.add('flex', 'items-start', 'flex-col', 'lg:flex-row', 'lg:gap-6', 'w-full', 'gap-12');
  
              var blogInfoWrapper = document.createElement('div')
              blogInfoWrapper.classList.add('flex', 'flex-col', 'lg:w-2/3', 'w-full' )
  
              var title = document.createElement('div');
              title.textContent = blog.title.split(' - ')[0].trim();
              title.classList.add('font-serif', 'uppercase');
  
              var location = document.createElement('div');
              location.classList.add('mb-5')
              if (blog.location) {
                location.textContent = "Location: " + blog.location.value;
              }
  
              if (blog.description) {
                var description = document.createElement('p');
                var strippedDescription = toHTML(blog.description.value).replace(/(<([^>]+)>)/gi, "");
                var words = strippedDescription.split(' ');
                
                if (words.length > 30) {
                    strippedDescription = words.slice(0, 30).join(' ') + '...';
                }
            
                description.textContent = strippedDescription;
              }
            
              var cta = document.createElement('a');
              cta.href = "/blogs/" + blog.handle;
              cta.classList.add('ml-0', 'mt-4','read-more-button');
              cta.textContent = "Read More"
  
              var featured_image = document.createElement('img');
              featured_image.classList.add('object-contain', 'h-[265px]', 'lg:w-1/3', 'w-max');
              if (blog.featured_image) {
                featured_image.setAttribute('src', `${blog.featured_image.reference.image.url}`)
              } else {
                featured_image.setAttribute('src', 'https://cdn.shopify.com/s/files/1/0735/1749/8643/files/maker-thumb.png?v=1687419031');
              }
  
  
  
              blogInfoWrapper.appendChild(title);
              blogInfoWrapper.appendChild(location);
              blogInfoWrapper.appendChild(description);
              blogInfoWrapper.appendChild(cta);

              li.appendChild(featured_image);
              li.appendChild(blogInfoWrapper);

              blogList.appendChild(li);
            });
          } else {
            const noResults = document.createElement('div');
            noResults.innerHTML = "No results found";
            noResults.classList.add('text-center')
            blogList.appendChild(noResults);
          }

    		}).catch((error) => {
    			console.log(error);
    		});
    }
  }
  
  customElements.define('maker-list', MakerList);
  